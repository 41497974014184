import Img from "@components/common/Img"
import { Link, navigate } from "gatsby"
import React from "react"
import { colorsMap } from "@src/build/constants"
import parse from "html-react-parser"
import clsx from "clsx"

// link component parser
function VisualText({
  copy,
  link,
  textColor,
  backgroundColor,
  blockWidth,
  imageSize,
  withImage,
  file,
}) {
  const bgColor = colorsMap[backgroundColor]
  const isHalf = blockWidth === "half"
  const isImageCentered = imageSize === "centered"
  const containerClasses = clsx([
    `col-span-12 u-aspect relative text-${textColor} bg-${bgColor} font-futura text-medium`,
    isHalf ? "u-aspect-4-5 md:col-span-6" : "u-aspect-32-13 md:col-span-12",
    isHalf ? "md:col-span-6" : "md:col-span-12",
    link && "cursor-pointer hover-block",
  ])

  function navigateHandler(url) {
    if (!url) return null

    return navigate(url)
  }

  return (
    <section
      className={containerClasses}
      onClick={() => navigateHandler(link?.url)}
    >
      <div
        className={`max-w-900 2xl:max-w-1320 p-10${
          withImage ? " relative z-1" : ""
        }`}
      >
        {copy && copy !== "" && parse(copy)}
        {link && (
          <p>
            <Link
              to={link.url}
              className="underline text-bodyL hover:no-underline"
            >
              {link.title}
            </Link>
          </p>
        )}
      </div>
      {withImage && (
        <div
          className={`w-full h-full absolute top-0 z-0 overflow-hidden ${
            isImageCentered ? "w-1/2 left-1/2" : "w-full left-0"
          }${isImageCentered ? " transform -translate-x-1/2" : ""}`}
        >
          <Img
            image={file}
            classNames={"select-none w-full h-full object-cover"}
            oldSchool
          />
        </div>
      )}
    </section>
  )
}

export default VisualText
