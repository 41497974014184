import React from "react"
import VideoPlayer from "@components/VideoPlayer"
import clsx from "clsx"
import { colStart } from "@src/build/helpers"

function Video({
  vimeoLink,
  autoplay: autoplayRaw,
  alignment,
  columns,
  isStory,
  isLast,
}) {
  const { portraitLink, landscapeLink } = vimeoLink
  const autoplay = JSON.parse(autoplayRaw)
  const columnStart = colStart(alignment, columns)
  const contClasses = clsx([
    `col-span-12 md:col-span-${columns} md:col-start-${columnStart} text-center`,
    isStory && !isLast && "pb-60",
  ])

  // hide native controls on Vimeo
  // add autoplay when in view - add it to video player in case of "autoplay: true"

  return (
    <section className={contClasses}>
      {(portraitLink || landscapeLink) && (
        <VideoPlayer
          srcPortrait={portraitLink}
          srcLandscape={landscapeLink}
          settings={{
            autoplay,
            loop: autoplay ? true : false,
            hideControls: autoplay ? true : false,
            muted: autoplay ? true : false,
          }}
          maxHNone={columns === 12}
        />
      )}
    </section>
  )
}

export default Video
