import { Link, graphql, useStaticQuery } from "gatsby"

import Img from "@components/common/Img"
import React from "react"
import { colorsMap } from "@src/build/constants"

function FeaturedProduct({
  blockWidth,
  backgroundColor,
  productSku,
  textColour,
}) {
  const bgColor = colorsMap[backgroundColor]
  const isHalf = blockWidth === "half"
  const { allShopifyProduct } = useStaticQuery(query)
  const { nodes: products } = allShopifyProduct
  const featured = products.filter(
    prod => prod.variants[0].sku === productSku
  )[0]

  if (!featured || !featured?.handle) return null

  return (
    <section
      className={`hover-block col-span-12 u-aspect flex relative ${
        isHalf ? "md:col-span-6" : "md:col-span-12"
      } text-${textColour} bg-${bgColor} font-futura text-bodyL ${
        isHalf ? "u-aspect-4-5" : "u-aspect-32-13"
      }`}
    >
      <Link
        to={`/shop/${featured.handle}`}
        className="p-10 flex flex-col w-full h-inherit z-1"
      >
        <h2 className="md:absolute md:top-10 md:left-10">{featured.title}</h2>
        <Img
          image={featured.images[0]}
          classNames={"max-w-2/3 mx-auto my-auto block"}
        />
      </Link>
    </section>
  )
}

const query = graphql`
  query {
    allShopifyProduct(
      sort: { fields: [createdAt], order: DESC }
      filter: { availableForSale: { eq: true } }
    ) {
      nodes {
        title
        handle
        images {
          id
          originalSrc
        }
        variants {
          sku
        }
      }
    }
  }
`

export default FeaturedProduct
