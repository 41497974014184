import PropTypes from "prop-types"
import React from "react"
import StoryCover from "@components/StoryCover"
import { colorsMap } from "@src/build/constants"
import { navigate } from "gatsby"

// remove max-h

function FeaturedStory({ featuredStory }) {
  const {
    title,
    uri,
    ACFStory: { storyCover },
    ACFStoryGeneral: { imageBlendingMode, storyPageBackgroundColor },
  } = featuredStory[0]

  const bgColor = colorsMap[storyPageBackgroundColor]

  function navigateHandler() {
    return navigate(uri)
  }

  return (
    <article
      onClick={navigateHandler}
      className={`featured-story col-span-12 grid grid-cols-12 relative cursor-pointer bg-${bgColor} pb-10 md:pb-0`}
    >
      <StoryCover title={title} uri={uri} storyCover={storyCover} isFeatured />
    </article>
  )
}

FeaturedStory.displayName = "FeaturedStory"

FeaturedStory.propTypes = {
  props: PropTypes.shape({
    featuredStory: PropTypes.shape({
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      ACFStory: PropTypes.shape({
        storyCover: PropTypes.shape({
          copyColor: PropTypes.string,
          excerpt: PropTypes.string,
          mediaAlignment: PropTypes.string,
          mediaType: PropTypes.string,
          coverVideoLink: PropTypes.string,
          coverImageFile: PropTypes.object,
        }),
      }),
      ACFStoryGeneral: PropTypes.shape({
        imageBlendingMode: PropTypes.string,
        storyPageBackgroundColor: PropTypes.string,
      }),
    }),
  }),
}

// FeaturedStory.defaultProps = {
//   props: {
//     featuredStory
//   }
// }

export default FeaturedStory
