import Img from "@components/common/Img"
import React from "react"
import { colStart } from "@src/build/helpers"
import clsx from "clsx"

function SingleImage({
  alignment,
  columns,
  blendMode,
  file,
  isSecondToLast,
  isLast,
  isStory,
}) {
  const columnStart = colStart(alignment, columns)
  const classes = clsx([
    `col-span-12 md:col-span-${columns} md:col-start-${columnStart} text-center`,
    !isLast && "pb-60",
    isSecondToLast && columns <= 6 && columnStart === 0 && "md:pb-0",
  ])

  if (!file) return null

  return (
    <section className={classes}>
      <Img
        image={file}
        blendMode={blendMode}
        paddedCaption={columnStart === 0}
        widthFull
      />
    </section>
  )
}

export default SingleImage
