import GridPreviews from "@components/GridPreviews"
import Preview from "@components/Preview"
import React from "react"

function StoriesIndex({ title, stories }) {
  // console.log({ stories })
  // check stories data

  function previewRendering() {
    return stories.map(
      ({
        id,
        title,
        slug,
        ACFStory: { storyCover },
        ACFStoryGeneral: storyGeneral,
      }) => {
        const hasImage =
          storyCover.mediaType === "image" && storyCover.coverImageFile
        const hasVideo =
          storyCover.mediaType === "video" && storyCover.coverVideoLink
        const hasMedia = hasImage || hasVideo

        return (
          <div
            key={id}
            className={`col-span-6 md:col-span-4 lg:col-span-3 ${
              hasMedia ? "" : "h-full"
            }`}
          >
            <Preview
              type="stories"
              className="max-w-4/5 md:max-w-2/3"
              slug={slug}
              title={title}
              hasMedia={hasMedia}
              image={
                storyCover.mediaType === "image" &&
                storyCover.coverImageFile &&
                storyCover.coverImageFile
              }
              video={
                storyCover.mediaType === "video" &&
                storyCover.coverVideoLink &&
                storyCover.coverVideoLink
              }
              bgColor={storyGeneral.storyPageBackgroundColor}
              blendMode={
                storyGeneral.imageBlendingMode !== "none"
                  ? storyGeneral.imageBlendingMode
                  : null
              }
            />
          </div>
        )
      }
    )
  }

  return (
    <section id="stories" className="col-span-12 p-10 bg-white">
      <h3 className="font-futura text-bodyL text-center mt-10 mb-165">
        {title}
      </h3>
      {stories && <GridPreviews>{previewRendering()}</GridPreviews>}
    </section>
  )
}

export default StoriesIndex
