import Img from "@components/common/Img"
import { Link } from "gatsby"
import React from "react"
import VideoPlayer from "@components/VideoPlayer"
import { colorsMap } from "@src/build/constants"

function Preview({
  className = "",
  type,
  slug,
  hasMedia,
  image,
  title,
  bgColor: bgColorPre,
  video,
  blendMode,
}) {
  const bgColor = colorsMap[bgColorPre]
  const prevInteraction = type === "stories"

  return (
    <article
      className={`text-center mx-auto ${className} ${
        !hasMedia ? "h-full" : ""
      }`}
    >
      <Link
        to={`/${type}/${slug}`}
        className={`block ${
          bgColor && !hasMedia ? `bg-${bgColor}` : ""
        } relative ${prevInteraction ? "preview-interaction" : ""} ${
          !hasMedia ? "h-full" : ""
        }`}
      >
        {image && (
          <Img
            image={image}
            classNames={"relative block z-2"}
            blendMode={blendMode}
            upTo="sm"
          />
        )}
        {video && (
          <VideoPlayer
            srcPortrait={
              video.vimeoLinkMobile
                ? video.vimeoLinkMobile
                : video.vimeoLinkDesktop
            }
            srcLandscape={video.vimeoLinkDesktop}
            settings={{
              autoplay: true,
              loop: true,
              hideControls: true,
              muted: true,
            }}
            classNames={"block z-2"}
          />
        )}
        {!prevInteraction && <h3 className="uppercase mt-20">{title}</h3>}
        {prevInteraction && (
          <div className="w-full mt-20 md:mt-0 md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 z-0 font-futura">
            <h3 className="text-bodyL md:text-medium mb-8">{title}</h3>
            <p className="hidden underline md:block mb-0">Read</p>
          </div>
        )}
      </Link>
    </article>
  )
}

export default Preview
