import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import styled from "styled-components"

function NewsletterOptin({
  openingText,
  inputPlaceholder,
  buttonCopy,
  successSmallMessage,
  existingSubscriptionSmallMessage,
  invalidEmailErrorMessage,
  successBigMessage,
  existingSubscriptionBigMessage,
}) {
  const [email, setEmail] = React.useState("")
  const [opening, setOpening] = React.useState(openingText)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

  function isEmailValid() {
    return emailRegEx.test(email)
  }

  async function handleSubmit(e) {
    e.preventDefault()

    if (!isEmailValid()) {
      return setOpening(invalidEmailErrorMessage)
    }

    const result = await addToMailchimp(email)

    if (result.result === "error") {
      setOpening(existingSubscriptionSmallMessage)

      return setError(true)
    }

    setOpening(successSmallMessage)

    return setSuccess(true)

    /**
      result.msg // Thank you for subscribing! - Looks like you're already subscribed
      result.result // success - error
    */
  }

  return (
    <section className="col-span-12 p-10 bg-white text-black">
      <p className="font-futura text-bodyL">{opening}</p>
      {(success || error) && (
        <p className="font-helvetica text-large flex-auto">
          {" "}
          {success ? successBigMessage : existingSubscriptionBigMessage}{" "}
        </p>
      )}
      {!success && !error && (
        <form
          onSubmit={handleSubmit}
          noValidate
          className="flex flex-row items-baseline"
        >
          <StyledInput
            type="email"
            name="EMAIL"
            id="EMAIL"
            placeholder={inputPlaceholder}
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="font-helvetica text-large outline-none flex-auto pt-20"
          />
          <button
            type="submit"
            disabled={email === ""}
            className="font-futura text-bodyL underline hover:no-underline ml-10 flex-initial"
          >
            {buttonCopy}
          </button>
        </form>
      )}
    </section>
  )
}

const StyledInput = styled.input`
  max-width: calc(
    100% - 60px
  ); /* 60px being the lenght of the button + 10px margin left */
  margin-bottom: -3rem;
`

export default NewsletterOptin
