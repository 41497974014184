import styled, { css } from "styled-components"

import Img from "@components/common/Img"
import React from "react"
import VideoPlayer from "@components/VideoPlayer"

function HeroCarousel({ media }) {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const interval = React.useRef(null)
  const mainImage = media[0]
  const mediaItems = media.slice(1)

  function indexHandler() {
    if (activeIndex === mediaItems.length) return setActiveIndex(0)

    return setActiveIndex(activeIndex + 1)
  }

  const intervalHandler = React.useCallback(
    () => setInterval(() => indexHandler(), 3000),
    [activeIndex, mediaItems, setActiveIndex]
  )

  function clickHandler() {
    clearInterval(interval.current)

    return indexHandler()
  }

  React.useEffect(() => {
    if (mediaItems.length > 1 && process.env.NODE_ENV === "production") {
      interval.current = intervalHandler()
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [activeIndex, mediaItems, intervalHandler])

  return (
    <section
      className="col-span-12 relative overflow-hidden min-h-screen max-h-screen cursor-pointer"
      onClick={clickHandler}
    >
      <Img
        image={mainImage.file}
        classNames={"absolute top-0 left-0 object-cover w-full h-full"}
        oldSchool
      />
      {mediaItems.map((item, i) => (
        <Placeholder
          key={i}
          active={i + 1 <= activeIndex}
          image={item.file}
          width={item.alignment !== "center" ? "50%" : "100%"}
          margin={JSON.parse(item.margin)}
          alignment={item.alignment}
        >
          {item.mediaType === "image" ? (
            <Img
              key={i}
              image={item.file}
              // classNames="max-w-full max-h-full w-auto"
              classNames="w-full h-full object-cover"
              oldSchool
            />
          ) : (
            <VideoPlayer
              key={i}
              srcPortrait={item.vimeoLink}
              settings={{
                autoplay: true,
                loop: true,
                hideControls: true,
                muted: true,
              }}
            />
          )}
        </Placeholder>
      ))}
    </section>
  )
}

const Placeholder = styled.div`
  display: ${({ active }) => (active ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  width: 100%;
  ${
    "" /* width: ${({ margin, width }) =>
    margin
      ? `calc(${width === "50%" ? "90%" : width} - 10%)`
      : width === "50%"
      ? "80%"
      : width}; */
  }
  height: ${({ margin }) => (margin ? "calc(100% - 10%)" : "100%")};
  overflow: hidden;
  position: absolute;
  top: ${({ margin }) => (margin ? "50%" : "0")};
  transform: ${({ margin }) => (margin ? "translateY(-50%)" : "none")};

  @media (min-width: 769px) {
    width: ${({ margin, width }) => (margin ? `calc(${width} - 10%)` : width)};
  }
  
  ${({ alignment, margin }) =>
    alignment === "left" &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (min-width: 769px) {
        top: ${({ margin }) => (margin ? "50%" : "0")};
        left: ${margin ? "5%" : "0"};
        transform: ${({ margin }) => (margin ? "translateY(-50%)" : "none")};
      }
    `}
  ${({ alignment, margin }) =>
    alignment === "right" &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (min-width: 769px) {
        top: ${({ margin }) => (margin ? "50%" : "0")};
        right: ${margin ? "5%" : "0"};
        left: unset;
        transform: ${({ margin }) => (margin ? "translateY(-50%)" : "none")};
      }
    `}
  ${({ alignment, margin }) =>
    alignment === "center" &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `};
`

// const StyledImg = styled(Img)`
//   display: ${({ active }) => (active ? "block" : "none")};
//   max-width: ${({ margin }) => (margin ? "calc(100% - 50px)" : "100%")};
//   width: auto;
//   max-height: ${({ margin }) => (margin ? "calc(100% - 50px)" : "100%")};
//   height: auto;
//   position: absolute;
//   top: ${({ margin }) => (margin ? "50%" : "0")};
//   transform: ${({ margin }) => (margin ? "translateY(-50%)" : "none")};

//     ${({ alignment, margin }) =>
//       alignment === "left" &&
//       css`
//         left: ${margin ? "25px" : "0"};
//         transform: translateY(-50%);
//       `}
//     ${({ alignment, margin }) =>
//       alignment === "right" &&
//       css`
//         right: ${margin ? "25px" : "0"};
//         transform: translateY(-50%);
//       `}
//     ${({ alignment, margin }) =>
//       alignment === "center" &&
//       css`
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//       `};
// `

// const StyledVideoPlayer = styled(VideoPlayer)`
//   display: ${({ active }) => (active ? "block" : "none")};
//   max-width: ${({ margin }) => (margin ? "calc(100% - 50px)" : "100%")};
//   width: 100%;
//   max-height: ${({ margin }) => (margin ? "calc(100% - 50px)" : "100%")};
//   height: 100%;
//   position: absolute;
//   top: ${({ margin }) => (margin ? "50%" : "0")};
//   transform: ${({ margin }) => (margin ? "translateY(-50%)" : "none")};

//     ${({ alignment, margin }) =>
//       alignment === "left" &&
//       css`
//         left: ${margin ? "25px" : "0"};
//         transform: translateY(-50%);
//       `}
//     ${({ alignment, margin }) =>
//       alignment === "right" &&
//       css`
//         right: ${margin ? "25px" : "0"};
//         transform: translateY(-50%);
//       `}
//     ${({ alignment, margin }) =>
//       alignment === "center" &&
//       css`
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//       `};
// `

export default HeroCarousel
