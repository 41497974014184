import styled from "styled-components"

import Img from "@components/common/Img"
import { Link } from "gatsby"
import React from "react"
import VideoPlayer from "@components/VideoPlayer"
import clsx from "clsx"
import { colorsMap } from "@src/build/constants"
import parse from "html-react-parser"
import useGeneralContext from "@components/common/hoc/useGeneralContext"

function StoryCover({
  title,
  uri,
  storyCover,
  blendMode,
  isFeatured,
  classNames,
}) {
  const {
    copyColor,
    mediaAlignment,
    storyCredits,
    coverImageFile,
    coverVideoLink,
    excerpt,
  } = storyCover
  const isCoverMediaHalf =
    mediaAlignment === "right" || mediaAlignment === "center"
  const isCoverMediaCenter = mediaAlignment === "center"
  const { currentBgColor } = useGeneralContext()
  const bgColor = colorsMap[currentBgColor]
  const mainContClasses = clsx([
    "col-span-12 px-10 relative md:u-aspect md:u-aspect-16-10 lg:u-aspect-16-9",
    copyColor && `md:text-${copyColor}`,
    classNames && classNames,
  ])
  const headingContClasses = clsx([
    "w-full flex flex-col align-start pb-10 md:h-full md:relative md:w-1/2 md:z-1",
    isFeatured ? "pt-10" : "pt-60",
  ])
  const imageContClasses = clsx([
    `w-full md:h-full md:absolute md:top-0 md:z-0 md:overflow-hidden bg-${bgColor}`,
    isCoverMediaCenter && "md:transform md:-translate-x-1/2",
  ])
  const hasVideo =
    coverVideoLink &&
    (coverVideoLink.vimeoLinkDesktop || coverVideoLink.vimeoLinkMobile)
  const titleClasses = clsx([
    "leading-none mb-20 md:mb-0 md:mt-8 text-large md:text-largeSm 2xl:text-large",
  ])

  return (
    <section className={mainContClasses}>
      <div className={headingContClasses}>
        <h1 className={titleClasses}>{title}</h1>
        {storyCredits && (
          <div className="flex md:mt-auto">
            {storyCredits.map((credit, i) => {
              return (
                <p key={i} className="flex-50 mb-0">
                  {credit.role}
                  <br />
                  <a
                    href={credit.link}
                    className="underline hover:no-underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {credit.name}
                  </a>
                </p>
              )
            })}
          </div>
        )}
        {(excerpt || uri) && (
          <div className="md:max-w-1/2">
            {excerpt && parse(excerpt)}
            {uri && (
              <Link to={uri} className="font-futura text-bodyL underline">
                Read story
              </Link>
            )}
          </div>
        )}
      </div>
      {(coverImageFile || hasVideo) && (
        <MediaContainer isHalf={isCoverMediaHalf} className={imageContClasses}>
          {coverImageFile && (
            <Img
              image={coverImageFile}
              blendMode={blendMode}
              classNames={"w-full h-full object-cover"}
              fullHeight
            />
          )}
          {hasVideo && (
            <VideoPlayer
              srcPortrait={
                coverVideoLink.vimeoLinkMobile
                  ? coverVideoLink.vimeoLinkMobile
                  : coverVideoLink.vimeoLinkDesktop
              }
              srcLandscape={coverVideoLink.vimeoLinkDesktop}
              settings={{
                autoplay: true,
                loop: true,
                hideControls: true,
                muted: true,
              }}
              classNames={"block z-2"}
            />
          )}
        </MediaContainer>
      )}
    </section>
  )
}

StoryCover.displayName = "StoryCover"

const MediaContainer = styled.div`
  @media (min-width: 769px) {
    ${
      "" /* width: ${({ isHalf }) => (isHalf ? "calc(50% - 10px)" : "100%")}; */
    }
    width: ${({ isHalf }) => (isHalf ? "50%" : "100%")};
    left: ${({ isHalf }) => (isHalf ? "50%" : 0)};
  }
`

export default StoryCover
