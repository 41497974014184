import { getAspectRatio, vimeoEmbedUrlParser } from "@src/helpers"
import { motion, useAnimation } from "framer-motion"
import styled, { css } from "styled-components"

import PropTypes from "prop-types"
import React from "react"
import ReactPlayer from "react-player/lazy"
import { findDOMNode } from "react-dom"
import loadable from "@loadable/component"
import screenfull from "screenfull"

const VideoControls = loadable(() =>
  import("@components/VideoPlayer/VideoControls")
)

function VideoPlayer({
  srcPortrait,
  srcLandscape,
  fullSize,
  parentLoadHandler,
  delay,
  variants,
  settings,
  classNames = "",
  className = "",
  maxHNone,
}) {
  const { autoplay, muted, loop, hideControls } = settings
  const controls = useAnimation()
  const isVimeo =
    srcPortrait?.includes("vimeo.") || srcLandscape?.includes("vimeo.")
  const player = React.useRef(null)
  const [src, setSrc] = React.useState(undefined)
  const [playing, setPlaying] = React.useState(
    autoplay && process.env.NODE_ENV === "production"
  )
  const [mute, setMute] = React.useState(muted)
  const [ended, setEnded] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const [duration, setDuration] = React.useState(0)
  const [ratio, setRatio] = React.useState(["16", "9"])
  const [pip, setPip] = React.useState(false)

  /**
    From React Player's doc:
    
    ◦  For Vimeo videos, hiding controls must be enabled by the video owner.
  */

  const config = {
    vimeo: {
      playerOptions: {
        byline: false,
        portrait: false,
        title: false,
        autopause: false,
        speed: true,
        transparent: true,
      },
    },
    youtube: {
      playerVars: {
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        playsinline: 1,
        controls: 0,
        fs: 0,
      },
    },
  }

  React.useEffect(() => {
    if (loaded) {
      setTimeout(() => controls.start("animate"), delay * 100)
    }
  }, [loaded])

  React.useEffect(() => {
    if (typeof window !== undefined) {
      window.innerWidth > 680 && srcLandscape
        ? setSrc(srcLandscape)
        : setSrc(srcPortrait)
    }
  })

  function loadHandler() {
    return setLoaded(true)
  }

  function progressHandler({ played }) {
    return setProgress(played)
  }

  function handleDuration(duration) {
    return setDuration(duration)
  }

  function handleTogglePIP() {
    return setPip(!pip)
  }

  function handleEnablePIP() {
    return setPip(true)
  }

  function handleDisablePIP() {
    return setPip(false)
  }

  function handleFullScreen() {
    screenfull.request(findDOMNode(player.current))
  }

  function playPauseHandler() {
    setPlaying(!playing)

    if (ended) {
      return setEnded(!ended)
    }
  }

  function muteUnmuteHandler() {
    return setMute(!mute)
  }

  function endedHandler() {
    setEnded(!ended)
    return setPlaying(!playing)
  }

  function aspectRatioHandler(el) {
    const width = el.player?.player?.element.width
    const height = el.player?.player?.element.height

    const calcRatio = getAspectRatio(width, height)

    if (ratio.toString() !== calcRatio.toString()) {
      setRatio(calcRatio)
    }

    return loadHandler()
  }

  return (
    <StyledVideoPlayer
      fullSize={fullSize}
      initial="initial"
      animate={controls}
      variants={variants}
      isVimeo={isVimeo}
      ratio={ratio}
      className={`video-player relative w-full ${
        maxHNone ? "" : "max-h-screen"
      } mx-auto ${classNames} ${className} select-none`}
    >
      <ReactPlayer
        ref={player}
        url={src}
        // width={fullSize ? "auto" : "100%"}
        // height={fullSize ? "inherit" : "100%"}
        width={"100%"}
        height={"100%"}
        config={config}
        playing={playing}
        muted={mute}
        loop={loop}
        pip={pip}
        volume={1}
        playsinline
        onProgress={e => progressHandler(e)}
        onDuration={e => handleDuration(e)}
        onEnablePIP={() => handleEnablePIP()}
        onDisablePIP={() => handleDisablePIP()}
        onReady={e => {
          if (isVimeo) {
            return aspectRatioHandler(e.player)
          }
          return loadHandler()
        }}
        onEnded={() => endedHandler()}
      />
      {!hideControls && (
        <VideoControls
          playing={playing}
          ended={ended}
          mute={mute}
          progress={progress * duration}
          pip={pip}
          playPauseHandler={playPauseHandler}
          muteUnmuteHandler={muteUnmuteHandler}
          handleTogglePIP={handleTogglePIP}
          canEnablePIP={ReactPlayer.canEnablePIP(src)}
          handleFullScreen={handleFullScreen}
        />
      )}
    </StyledVideoPlayer>
  )
}

VideoPlayer.propTypes = {
  srcPortrait: PropTypes.string,
  srcLandscape: PropTypes.string,
  parentLoadHandler: PropTypes.func,
  variants: PropTypes.object,
  delay: PropTypes.number,
  fullSize: PropTypes.bool,
  settings: PropTypes.object,
}

VideoPlayer.defaultProps = {
  srcPortrait: "",
  srcLandscape: "",
  parentLoadHandler: () => {},
  variants: {},
  delay: 0,
  fullSize: false,
  settings: {},
}

const StyledVideoPlayer = styled(motion.div)`
  ::before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    padding-top: ${({ ratio }) => `calc(100% / (${ratio[0]} / ${ratio[1]}))`};
  }

  video,
  > div {
    max-width: 100%;
  }

  > div:first-child {
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
  }

  iframe {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  ${({ fullSize, ratio }) =>
    fullSize &&
    css`
      max-width: 100%;
      max-height: inherit;
      width: calc((100vh * ${ratio[0]}) / ${ratio[1]});
      height: calc((100vw * ${ratio[0]}) / ${ratio[1]});
      background-color: black;
      padding-top: 0;

      ::before {
        display: none;
      }

      > div:first-child {
        position: static;
      }
    `}
`

export default VideoPlayer
