import FeaturedProduct from "@components/blocks/FeaturedProduct"
import FeaturedStory from "@components/blocks/FeaturedStory"
import HeroCarousel from "@components/blocks/HeroCarousel"
import Lists from "@components/blocks/Lists"
import MediaCarousel from "@components/blocks/MediaCarousel"
import NewsletterOptin from "@components/blocks/NewsletterOptin"
import Paragraph from "@components/blocks/Paragraph"
import ProductGrid from "@components/blocks/ProductGrid"
import SingleImage from "@components/blocks/SingleImage"
import StoriesIndex from "@components/blocks/StoriesIndex"
import Video from "@components/blocks/Video"
import VisualText from "@components/blocks/VisualText"

export default [
  {
    type: [
      "Story_Acfstory_StoryBody_ParagraphBlock",
      "page_Acfpage_Page_ParagraphBlock",
      "Product_Acfproduct_Page_ParagraphBlock",
    ],
    block: Paragraph,
  },
  {
    type: [
      "Story_Acfstory_StoryBody_SingleImageBlock",
      "page_Acfpage_Page_SingleImageBlock",
      "Product_Acfproduct_Page_SingleImageBlock",
    ],
    block: SingleImage,
  },
  {
    type: [
      "Story_Acfstory_StoryBody_MediaCarouselBlock",
      "page_Acfpage_Page_MediaCarouselBlock",
      "Product_Acfproduct_Page_MediaCarouselBlock",
    ],
    block: MediaCarousel,
  },
  {
    type: ["page_Acfpage_Page_HeroCarouselBlock"],
    block: HeroCarousel,
  },
  {
    type: [
      "page_Acfpage_Page_FeaturedStoryBlock",
      "Story_Acfstory_StoryBody_FeaturedStoryBlock",
    ],
    block: FeaturedStory,
  },
  {
    type: ["page_Acfpage_Page_StoriesIndexBlock"],
    block: StoriesIndex,
  },
  {
    type: [
      "page_Acfpage_Page_NewsletterOptinBlock",
      "Product_Acfproduct_Page_NewsletterOptinBlock",
    ],
    block: NewsletterOptin,
  },
  {
    type: [
      "page_Acfpage_Page_ListsBlock",
      "Product_Acfproduct_Page_ListsBlock",
    ],
    block: Lists,
  },
  {
    type: [
      "page_Acfpage_Page_VisualTextBlock",
      "Product_Acfproduct_Page_VisualTextBlock",
    ],
    block: VisualText,
  },
  {
    type: [
      "page_Acfpage_Page_VideoBlock",
      "Product_Acfproduct_Page_VideoBlock",
      "Story_Acfstory_StoryBody_VideoBlock",
    ],
    block: Video,
  },
  {
    type: ["page_Acfpage_Page_FeaturedProductBlock"],
    block: FeaturedProduct,
  },
  {
    type: ["page_Acfpage_Page_ProductsGridBlock"],
    block: ProductGrid,
  },
]
