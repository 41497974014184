import "react-multi-carousel/lib/styles.css"
import Carousel from "react-multi-carousel"
import Img from "@components/common/Img"
import MediaCarouselMobile from "@components/MediaCarouselMobile.jsx"
import React from "react"
import VideoPlayer from "@components/VideoPlayer"
import clsx from "clsx"
import { colorsMap } from "@src/build/constants"
import useGeneralContext from "@components/common/hoc/useGeneralContext"
import { useMediaQuery } from "@components/common/hoc/useMediaQuery"
import styled from "styled-components"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

function CustomArrow({ onClick, ...rest }) {
  const {
    pos,
    onMove,
    carouselState: { currentSlide },
  } = rest

  return (
    <button
      className={`cursor-pointer absolute top-0 h-full w-1/3 max-w-650 focus:outline-none ${
        pos === "right" ? "right-0 green" : "left-0 blue"
      }`}
      onClick={() => !onMove && onClick()}
    />
  )
}

function MediaCarousel({ blendMode, media, isStory, isLast }) {
  const { currentBgColor } = useGeneralContext()
  const bgColor = colorsMap[currentBgColor]
  const activeMq = useMediaQuery()
  const contClasses = clsx([
    "col-span-12 pt-40 md:pt-60 lg:pt-120",
    !isLast && "pb-100 md:pb-120 lg:pb-180",
  ])

  if (process.env.NODE_ENV !== "production") return null

  return (
    <div className={contClasses}>
      {activeMq === "sm" ? (
        <MediaCarouselMobile items={media}>
          <ul className="flex w-full space-x-14 items-center px-14">
            <li style={{ maxWidth: "14px", minWidth: "1px" }}>&nbsp;</li>
            {media.map((item, i) => (
              <li key={i}>
                {item.mediaType === "image" ? (
                  <Img
                    key={i}
                    image={item.image}
                    visibleByDefault={i < 2 || i === media.length - 1}
                    blendMode={blendMode}
                  />
                ) : (
                  <VideoPlayer
                    key={i}
                    srcPortrait={item.videoLink.portraitLink}
                    srcLandscape={item.videoLink.landscapeLink}
                    settings={{
                      autoplay: true,
                      loop: true,
                      hideControls: true,
                      muted: true,
                    }}
                  />
                )}
              </li>
            ))}
            <li style={{ maxWidth: "14px", minWidth: "1px" }}>&nbsp;</li>
          </ul>
        </MediaCarouselMobile>
      ) : (
        <Carousel
          swipeable
          draggable
          ssr
          keyBoardControl
          centerMode
          infinite
          customRightArrow={<CustomArrow pos="right" />}
          customLeftArrow={<CustomArrow pos="left" />}
          itemClass={`media-carousel-item bg-${bgColor}`}
          responsive={responsive}
        >
          {media.map((item, i) =>
            item.mediaType === "image" ? (
              <Img
                key={i}
                image={item.image}
                visibleByDefault={true}
                blendMode={blendMode}
              />
            ) : (
              <VideoPlayer
                key={i}
                srcPortrait={item.videoLink.portraitLink}
                srcLandscape={item.videoLink.landscapeLink}
                settings={{
                  autoplay: true,
                  loop: true,
                  hideControls: true,
                  muted: true,
                }}
              />
            )
          )}
        </Carousel>
      )}
    </div>
  )
}

MediaCarousel.displayName = "MediaCarousel"

export default MediaCarousel
