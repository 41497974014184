import React from "react"
import blocksMap from "@components/blocks/blocksMap"

// importing React here feels weird
// below function to be refactored

export function renderBlocks(blocks) {
  if (!blocks) return null

  return blocks.map((block, i) => {
    const currentBlock = blocksMap.filter(b =>
      b.type.includes(block.fieldGroupName)
    )[0]
    const Component = currentBlock?.block

    if (!Component) return null

    const isStory = block.fieldGroupName.includes("Story_")

    return (
      <Component
        key={i}
        order={i}
        isStory={isStory}
        isLast={i === blocks.length - 1}
        isSecondToLast={i === blocks.length - 2}
        {...block}
      />
    )
  })
}

export function colStart(align, cols) {
  const totalCols = 12

  if (align === "left") {
    return 0
  } else if (align === "center") {
    return Math.round((totalCols - cols) / 2 + 1)
  } else if (align === "right") {
    return Math.round(totalCols - cols + 1)
  }
}
