import React from "react"
import clsx from "clsx"
import { colStart } from "@src/build/helpers"
import { colorsMap } from "@src/build/constants"
import parse from "html-react-parser"

function Paragraph({
  backgroundColor,
  textColour,
  copy,
  isLast,
  columns,
  alignment,
}) {
  const bgColor = colorsMap[backgroundColor]
  const columnStart = colStart(alignment, columns)
  const contClasses = clsx([
    "col-span-12 grid grid-cols-12 px-10",
    backgroundColor && `bg-${bgColor}`,
    textColour && `text-${textColour}`,
    !isLast && "pb-50",
  ])
  const innerContClasses = clsx([
    columns && `col-span-12 md:col-span-${columns} md:col-start-${columnStart}`,
  ])

  if (!copy) return null

  return (
    <section className={contClasses}>
      <div className={innerContClasses}>{parse(copy)}</div>
    </section>
  )
}

export default Paragraph
