import React from "react"

function GridPreviews({ children }) {
  return (
    // <div className="grid grid-cols-12 px-20 pb-20 mx-auto gap-x-20 gap-y-20 justify-around items-center md:px-60 md:pb-60 md:gap-x-30 md:gap-y-30 lg:gap-x-60 lg:gap-y-60">
    <div className="grid grid-cols-12 px-20 pb-20 mx-auto gap-y-20 justify-around items-center md:px-60 md:pb-60 md:gap-y-30 lg:gap-y-60">
      {children}
    </div>
  )
}

export default GridPreviews
