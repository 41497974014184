import React from "react"
import { colorsMap } from "@src/build/constants"
import parse from "html-react-parser"

function Lists({
  backgroundColor,
  leftList,
  openingText,
  rightList,
  textColour,
}) {
  const bgColor = colorsMap[backgroundColor]

  return (
    <section
      className={`col-span-12 text-${textColour} bg-${bgColor} u-aspect u-aspect-32-13 p-10`}
    >
      <div className={`grid grid-cols-12`}>
        <div className="col-span-12 mb-20 text-largeSm">
          {parse(openingText)}
        </div>
        <div className="col-span-12 mb-20 md:col-span-4 md:mb-0">
          {parse(leftList)}
        </div>
        <div className="col-span-12 md:col-span-4">{parse(rightList)}</div>
      </div>
    </section>
  )
}

export default Lists
