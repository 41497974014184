import { graphql, useStaticQuery } from "gatsby"

import GridPreviews from "@components/GridPreviews"
import Preview from "@components/Preview"
import React from "react"

function ProductGrid({ productsGrid }) {
  const { allShopifyProduct } = useStaticQuery(query)

  if (!productsGrid) return null

  return (
    <section className="col-span-12">
      <GridPreviews>
        {allShopifyProduct.nodes.map(
          ({ title, handle, id, images: [image] }) => (
            <div key={id} className="col-span-12 sm:col-span-6 md:col-span-4">
              <Preview
                type="shop"
                className="max-w-full sm:max-w-4/5 md:max-w-2/3"
                slug={handle}
                image={image}
                title={title}
              />
            </div>
          )
        )}
      </GridPreviews>
    </section>
  )
}

const query = graphql`
  query {
    allShopifyProduct(
      sort: { fields: [createdAt], order: DESC } # filter: { availableForSale: { eq: true } }
    ) {
      nodes {
        title
        handle
        id
        images {
          id
          originalSrc
        }
      }
    }
  }
`

export default ProductGrid
